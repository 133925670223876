// export const validateYouTubeUrl = (url) => {
//     const regex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}([?&].*)?$/;
//     return regex.test(url);
// }
export const validateYouTubeUrl = (url) => {
  const regex = /^(https?:\/\/)?(www\.)?(m\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}([?&].*)?$/;
  return regex.test(url);
}

export const getVideoId = (url) => {
  let videoId = null;

  // Check for 'youtu.be'
  if (url.includes('youtu.be/')) {
      videoId = url.split('youtu.be/')[1].split(/[?&]/)[0];

  } else if (url.includes('youtube.com/watch?v=')) {
      videoId = url.split('v=')[1].split(/[?&]/)[0];
  }

  return videoId;
}

export function generateGameId() {
    return Math.random().toString(36).substr(2, 9);
  }
  


export const fetchTranscript = async (url) => {
    const videoId = getVideoId(url)

    const response = await fetch(
      `/youtube?url=${encodeURIComponent(videoId)}`
    );
    console.log('response', response)
    const transcript = await response.json();
    console.log('transcript is ', transcript.transcript);
    return transcript.transcript
    }