'use client'

import React, { useState } from 'react';
import Content from './content'
import EnhancedComponent from '../EnhancedComponent';


// Define the pre-created categories, subcategories, and topics

const NewLearningPath = () => {
  // State Variables


  return (
    <EnhancedComponent>
   <Content />
    </EnhancedComponent>
  );
};

export default NewLearningPath;