'use client'

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { createJourney } from '@studygenius/shared/Requests';
import EnhancedComponent from '../EnhancedComponent';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import StarIcon from '@mui/icons-material/Star';
import { MUIStyles } from '@studygenius/shared/Variables';
import { useRouter } from 'next/navigation';
import { PrimaryLoadingButton } from '../ClientComponents';
import colors from '@studygenius/shared/Colors';
import { generateGameId } from '../youtube-summarizer-quiz-generator/youtubeUtils';
import { useSelector } from 'react-redux';

// Define the pre-created categories, subcategories, and topics


const preCreatedTopics = [
  {
    category: 'Overview Studies',
    subcategories: [
      {
        subcategory: 'Comprehensive Overviews',
        topics: [
          'The Bible Overview', // A 10-hour comprehensive overview of the entire Bible.
          'Old Testament Overview', // A 10-hour survey of the Old Testament.
          'New Testament Overview', // A 10-hour survey of the New Testament.
          'Major Themes of the Bible', // Exploring key themes throughout the Bible in 10 hours.
          'Introduction to Biblical Theology', // An overview of biblical theology in 10 hours.
        ],
      },
    ],
  },
  {
    category: 'Book Studies',
    subcategories: [
      {
        subcategory: 'Pentateuch (Torah)',
        topics: [
          'Genesis', // 10-hour study of Genesis.
          'Exodus', // 10-hour study of Exodus.
          'Leviticus and Numbers', // Combined 10-hour study of Leviticus and Numbers.
          'Deuteronomy', // 10-hour study of Deuteronomy.
        ],
      },
      {
        subcategory: 'Historical Books',
        topics: [
          'Joshua and Judges', // Combined 10-hour study.
          'Ruth and Samuel', // Ruth, 1 & 2 Samuel combined in 10 hours.
          'Kings and Chronicles', // 1 & 2 Kings, 1 & 2 Chronicles in 10 hours.
          'Ezra, Nehemiah, and Esther', // Combined study in 10 hours.
        ],
      },
      {
        subcategory: 'Wisdom Literature',
        topics: [
          'Job and Ecclesiastes', // Combined 10-hour study.
          'Psalms', // 10-hour study focusing on selected Psalms.
          'Proverbs and Song of Solomon', // Combined 10-hour study.
        ],
      },
      {
        subcategory: 'Major Prophets',
        topics: [
          'Isaiah', // 10-hour study.
          'Jeremiah and Lamentations', // Combined 10-hour study.
          'Ezekiel', // 10-hour study.
          'Daniel', // 10-hour study.
        ],
      },
      {
        subcategory: 'Minor Prophets',
        topics: [
          'The Minor Prophets', // 10-hour overview covering all 12 minor prophets.
        ],
      },
      {
        subcategory: 'Gospels and Acts',
        topics: [
          'Matthew and Mark', // Combined 10-hour study.
          'Luke and Acts', // Combined 10-hour study.
          'John', // 10-hour study.
        ],
      },
      {
        subcategory: 'Pauline Epistles',
        topics: [
          'Romans', // 10-hour study.
          '1 & 2 Corinthians', // Combined 10-hour study.
          'Galatians, Ephesians, Philippians, Colossians', // Combined 10-hour study.
          'Thessalonians and Pastoral Epistles', // 1 & 2 Thessalonians, 1 & 2 Timothy, Titus in 10 hours.
          'Philemon and Hebrews', // Combined 10-hour study.
        ],
      },
      {
        subcategory: 'General Epistles and Revelation',
        topics: [
          'James and Jude', // Combined 10-hour study.
          '1 & 2 Peter', // Combined 10-hour study.
          '1, 2, & 3 John', // Combined 10-hour study.
          'Revelation', // 10-hour study.
        ],
      },
    ],
  },
  {
    category: 'Denominational Studies',
    subcategories: [
      {
        subcategory: 'Catholicism',
        topics: [
          'Catholic Beliefs and Practices', // Core doctrines and sacraments.
          'The Role of the Pope and Church Authority', // 10-hour study.
          'Veneration of Saints and Mary', // 10-hour study.
          'Catholic Liturgy and Worship', // 10-hour study.
        ],
      },
      {
        subcategory: 'Protestantism',
        topics: [
          'Reformation History and Theology', // 10-hour study of Protestant origins.
          'Lutheran Beliefs and Practices', // 10-hour study.
          'Calvinism and Reformed Theology', // 10-hour study.
          'Anglicanism: Tradition and Beliefs', // 10-hour study.
          'Baptist Traditions', // 10-hour study.
          'Methodism and Wesleyan Theology', // 10-hour study.
          'Pentecostalism and Charismatic Movements', // 10-hour study.
        ],
      },
      {
        subcategory: 'Eastern Orthodoxy',
        topics: [
          'Orthodox Beliefs and Practices', // Core doctrines.
          'The Divine Liturgy and Sacraments', // 10-hour study.
          'Icons and Worship in Orthodoxy', // 10-hour study.
          'Church Fathers and Tradition', // 10-hour study.
        ],
      },
      {
        subcategory: 'Other Christian Traditions',
        topics: [
          'Anabaptist Beliefs (Mennonites, Amish)', // 10-hour study.
          'Seventh-day Adventist Beliefs', // 10-hour study.
          'Restoration Movement (Churches of Christ)', // 10-hour study.
          'Messianic Judaism', // 10-hour study.
  
          'Non-Denominational Christianity', // 10-hour study.

        ],
      },
      {
        subcategory: 'Emerging and Contemporary Movements',
        topics: [
          'African Indigenous Churches', // 10-hour study.
          'Latin American Liberation Theology', // 10-hour study.
          'Asian Christian Perspectives', // 10-hour study.
          'Prosperity Gospel Movement', // 10-hour study.
        ],
      },
    ],
  },
];

const NewLearningPathContent = () => {
  // State Variables
  const userName = useSelector((state) => state.websocket.userProfile?.name);
  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState(['Category', 'Subcategory', 'Topic', 'Confirmation']);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [customCategory, setCustomCategory] = useState('');
  const [customSubcategory, setCustomSubcategory] = useState('');
  const [customTopic, setCustomTopic] = useState('');
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [id] = useState(generateGameId());
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const userId = useSelector((state) => state.websocket.userProfile?.id || '');
  const [name, setName] = useState(userName || '');
  const router = useRouter();

  // Handle Next Button
  const handleNext = () => {
    if (activeStep === 0 && !(selectedCategory || customCategory)) {
      return;
    }
    if (activeStep === 1 && !(selectedSubcategory || customSubcategory)) {
      return;
    }
    if (activeStep === 2 && !(selectedTopic || customTopic)) {
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  // Handle Back Button
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);

    if (activeStep === 1) {
      // Reset subcategory and topic if going back from Subcategory step
      setSelectedSubcategory('');
      setSelectedTopic('');
      setCustomSubcategory('');
      setCustomTopic('');
    } else if (activeStep === 2) {
      // Reset topic if going back from Topic step
      setSelectedTopic('');
      setCustomTopic('');
    }
  };

  // Disable Next Button Logic
  const isNextDisabled = () => {
    switch (steps[activeStep]) {
      case 'Category':
        return !(selectedCategory || customCategory);
      case 'Subcategory':
        return !(selectedSubcategory || customSubcategory);
      case 'Topic':
        return !(selectedTopic || customTopic);
      default:
        return false;
    }
  };

  // Reset the form
  const handleReset = () => {
    setActiveStep(0);
    setSelectedCategory('');
    setSelectedSubcategory('');
    setSelectedTopic('');
    setCustomCategory('');
    setCustomSubcategory('');
    setCustomTopic('');
    setShowAdvancedOptions(false);
    setName(userName || '');
  };

  // Get subcategories based on selected category
  const currentSubcategories = () => {
    const categoryObj = preCreatedTopics.find(
      (item) => item.category === selectedCategory
    );
    return categoryObj ? categoryObj.subcategories : [];
  };

  // Get topics based on selected subcategory
  const currentTopics = () => {
    const subcategoryObj = currentSubcategories().find(
      (item) => item.subcategory === selectedSubcategory
    );
    return subcategoryObj ? subcategoryObj.topics : [];
  };

  // Render Functions for Each Step
  const renderStepContent = () => {
    switch (steps[activeStep]) {
      case 'Category':
        return (
          <>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ ...MUIStyles.smallHeader, color: '#fff' }}
            >
              <SportsEsportsIcon
                sx={{ fontSize: '2rem', verticalAlign: 'middle', mr: 1 }}
              />
              Choose a category to start
            </Typography>
            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
              <InputLabel
                id="category-label"
                sx={{ color: '#fff', '&.Mui-focused': { color: '#fff' } }}
              >
                Select Category
              </InputLabel>
              <Select
                labelId="category-label"
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setSelectedSubcategory('');
                  setSelectedTopic('');
                  setCustomCategory('');
                }}
                label="Select Category"
                sx={{
                  color: '#fff',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.main,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#fff',
                  },
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
              >
                {preCreatedTopics.map((topic) => (
                  <MenuItem key={topic.category} value={topic.category}>
                    {topic.category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showAdvancedOptions && (
              <TextField
                placeholder="Enter a custom category"
                variant="outlined"
                fullWidth
                value={customCategory}
                onChange={(e) => {
                  setCustomCategory(e.target.value);
                  setSelectedCategory('');
                }}
                helperText="You can be as broad or as specific as you like."
                sx={{
                  mt: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '4px',
                }}
              />
            )}
          </>
        );
      case 'Subcategory':
        return (
          <>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ ...MUIStyles.smallHeader, color: '#fff' }}
            >
              <SportsEsportsIcon
                sx={{ fontSize: '2rem', verticalAlign: 'middle', mr: 1 }}
              />
              Choose a subcategory
            </Typography>
            {selectedCategory && currentSubcategories().length > 0 && (
              <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="subcategory-label"
                  sx={{ color: '#fff', '&.Mui-focused': { color: '#fff' } }}
                >
                  Select Subcategory
                </InputLabel>
                <Select
                  labelId="subcategory-label"
                  value={selectedSubcategory}
                  onChange={(e) => {
                    setSelectedSubcategory(e.target.value);
                    setSelectedTopic('');
                    setCustomSubcategory('');
                  }}
                  label="Select Subcategory"
                  sx={{
                    color: '#fff',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.main,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {currentSubcategories().map((sub) => (
                    <MenuItem key={sub.subcategory} value={sub.subcategory}>
                      {sub.subcategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {showAdvancedOptions && (
              <TextField
                placeholder="Enter a custom subcategory"
                variant="outlined"
                fullWidth
                value={customSubcategory}
                onChange={(e) => {
                  setCustomSubcategory(e.target.value);
                  setSelectedSubcategory('');
                }}
                sx={{
                  mt: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '4px',
                }}
              />
            )}
          </>
        );
      case 'Topic':
        return (
          <>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ ...MUIStyles.smallHeader, color: '#fff' }}
            >
              <SportsEsportsIcon
                sx={{ fontSize: '2rem', verticalAlign: 'middle', mr: 1 }}
              />
              Choose a topic
            </Typography>
            {selectedSubcategory && currentTopics().length > 0 && (
              <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="topic-label"
                  sx={{ color: '#fff', '&.Mui-focused': { color: '#fff' } }}
                >
                  Select Topic
                </InputLabel>
                <Select
                  labelId="topic-label"
                  value={selectedTopic}
                  onChange={(e) => {
                    setSelectedTopic(e.target.value);
                    setCustomTopic('');
                  }}
                  label="Select Topic"
                  sx={{
                    color: '#fff',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.main,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {currentTopics().map((topic) => (
                    <MenuItem key={topic} value={topic}>
                      {topic}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {showAdvancedOptions && (
              <TextField
                placeholder="Enter a custom topic"
                variant="outlined"
                fullWidth
                value={customTopic}
                onChange={(e) => {
                  setCustomTopic(e.target.value);
                  setSelectedTopic('');
                }}
                sx={{
                  mt: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '4px',
                }}
              />
            )}
          </>
        );
      case 'Confirmation':
        return (
          <>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ ...MUIStyles.smallHeader, color: '#fff' }}
            >
              <SportsEsportsIcon
                sx={{ fontSize: '2rem', verticalAlign: 'middle', mr: 1 }}
              />
              Confirmation
            </Typography>
            <Typography
              variant="body1"
              sx={{ ...MUIStyles.latoMediumParagraph, mt: 2, color: '#fff' }}
            >
              Category: <strong>{selectedCategory || customCategory}</strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{ ...MUIStyles.latoMediumParagraph, mt: 1, color: '#fff' }}
            >
              Subcategory: <strong>{selectedSubcategory || customSubcategory}</strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{ ...MUIStyles.latoMediumParagraph, mt: 1, color: '#fff' }}
            >
              Topic: <strong>{selectedTopic || customTopic}</strong>
            </Typography>
            <TextField
              value={name}
              InputProps={{
                sx: {
                  color: '#fff',
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    opacity: 1,
                  },
                },
              }}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              sx={{
                border: `2px solid ${colors.main}`,
                borderRadius: '8px',
                width: '100%',
                position: 'relative',
                zIndex: 10,
                marginY: 2,
              }}
            />
          </>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  // Custom Step Icon Component
  const CustomStepIcon = (props) => {
    const { active, className } = props;

    return (
      <StarIcon
        className={className}
        sx={{ color: active ? colors.main : '#fff' }}
      />
    );
  };

  return (
      <Box
        sx={{
          width: 'calc(100% - 32px)',
          minHeight: 'calc(100vh - 100px)',
          backgroundImage: 'url(/background-5.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 600,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            borderRadius: '16px',
            padding: '32px',
            boxShadow: '0px 0px 20px rgba(0,0,0,0.5)',
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Typography sx={{ ...MUIStyles.smallBold, color: '#fff' }}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {renderStepContent()}

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                mr: 1,
                ...MUIStyles.smallBold,
                color: '#fff',
                borderColor: '#fff',
              }}
              variant="outlined"
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <>
                <Button
                  onClick={handleReset}
                  sx={{ ...MUIStyles.smallBold, color: '#fff' }}
                  variant="text"
                >
                  Start Over
                </Button>
                <PrimaryLoadingButton
                  variant="contained"
                  color="primary"
                  loading={isProceedLoading}
                  disabled={isProceedLoading}
                  sx={{ ml: 1, ...MUIStyles.smallBold }}
                  disabledColor={'#aaa !important'}
                  onClick={() => {
                    setIsProceedLoading(true);
                    const category = selectedCategory || customCategory;
                    const subcategory = selectedSubcategory || customSubcategory;
                    const topic = selectedTopic || customTopic;
                    createJourney(
                      userId,
                      id,
                      `${category}: ${subcategory}`,
                      topic,
                      name
                    );
                    router.push(`/BattleGame?gameId=${id}`);
                  }}
                >
                  Proceed
                </PrimaryLoadingButton>
              </>
            ) : (
              <PrimaryLoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={isNextDisabled()}
                loading={false}
                disabledColor={'#aaa !important'}
              >
                Next
              </PrimaryLoadingButton>
            )}
          </Box>
        </Box>
      </Box>
  );
};

export default NewLearningPathContent;